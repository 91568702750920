//color palette: TODO
$bg-color: #111823; //ok
$primary: #D04614;
$text-color: #FBF7ED;
$success: #11C923;
//$info: #650219;
$info: #FF7F50;
$danger: #B50B0B;



$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;


.sticky-sidebar {
  .nav-link:hover {
    color: $primary !important;
    .casino-icon path {
      fill: $primary !important;
    }
  }
  .active-link {
    color: $primary !important;
    .casino-icon {
      opacity: 1;
      path {
        fill: $primary !important;
      }
    }
  }
}

//have a better looking hamburger menu and icon in sidebar
header {
  .navbar-brand img {
    max-height: 70px;
    margin: -8px 0;
    max-width: 230px !important;
  }
}
.logo-and-nav{
  @media (min-width: 768px) {
    visibility: hidden;
    button {
      display: none;
    }
    .navbar-brand img {
      height: 40px;
    }
  }
}

.logo-and-nav-in-sidebar {
  display: none;
  @media (min-width: 768px) {
    display: block;
    .navbar-brand {
      display: block;
      text-align: center;

      img {
        max-width: 220px;
      }
    }

    .close-btn {
      text-align: right;
      svg {
        width: 1.5rem;
        fill: $text-color;
        opacity:0.5;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

}

#sidebar-wrapper {
  &.sticky-sidebar {
    top: 0;
    margin-top: 110px;

    @media (min-width: 576px) {
      margin-top: 66px;
    }

    @media (min-width: 768px) {
      margin-top: 0;
      z-index: 2000;
      height: 100vh;
    }

    &.with-token {
      margin-top: 48px;
      @media (min-width: 576px) {
        margin-top: 66px;
      }
      @media (min-width: 768px) {
        margin-top: 0;
      }
    }
  }

}

#main-wrapper {
  margin-top: 120px;

  &.with-token {
    margin-top: 76px;
    @media (min-width: 768px) {
      &.sticky-main {
        margin-top: 50px;
      }
    }
  }

  @media (min-width: 576px) {
    margin-top: 76px;
  }

}




